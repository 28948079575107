import { ACL_ROLE_ADMINISTRATOR, AdminACL } from './admin';
import { ACL_ROLE_ZEE_ADMIN, FranchiseAdminACL } from './franchise_admin';
import { ACL_ROLE_OWNER, FranchiseOwnerACL } from './franchise_owner';
import { ACL_ROLE_GAMECOACH, GameCoachACL } from './gamecoach';
import { ACL_ROLE_BOOKING_AGENT, BookingAgentACL } from './gtl_booking_agent';
import { ACL_ROLE_GTL_STAFF, GTLicensingACL } from './gtl_staff';
import { ACL_ROLE_HEAD_GAMECOACH, HeadGameCoachACL } from './head_gamecoach';
import { ACL_ROLE_MANAGER, ManagerACL } from './manager';
import { ACL_ROLE_NEWBIE, NewbieACL } from './newbie';
import { ACL_ROLE_REPORTING, ReportingACL } from './reporting';
import { ACL_ROLE_SUPER_ADMINISTRATOR, SuperAdminACL } from './superadmin';

export type AclRole =
  | typeof ACL_ROLE_SUPER_ADMINISTRATOR
  | typeof ACL_ROLE_ADMINISTRATOR
  | typeof ACL_ROLE_GTL_STAFF
  | typeof ACL_ROLE_BOOKING_AGENT
  | typeof ACL_ROLE_OWNER
  | typeof ACL_ROLE_ZEE_ADMIN
  | typeof ACL_ROLE_MANAGER
  | typeof ACL_ROLE_HEAD_GAMECOACH
  | typeof ACL_ROLE_GAMECOACH
  | typeof ACL_ROLE_NEWBIE
  | typeof ACL_ROLE_REPORTING;

export const ACL_ROLES: Record<AclRole, ACL> = {
  'Super Administrator': SuperAdminACL,
  Administrator: AdminACL,
  'GT Licensing Staff': GTLicensingACL,
  'Booking Agent': BookingAgentACL,
  'Franchise Owner': FranchiseOwnerACL,
  'Franchise Admin': FranchiseAdminACL,
  Manager: ManagerACL,
  'Head Game Coach': HeadGameCoachACL,
  'Game Coach': GameCoachACL,
  Newbie: NewbieACL,
  Reporting: ReportingACL,
};

export interface ACL {
  role: AclRole;
  is_superadmin: boolean;
  is_admin: boolean;
  dashboard: {
    view: boolean;
    news: boolean;
    motd: boolean;
  };
  calendar: {
    view: boolean;
    notes: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
  };
  scheduler: {
    view: boolean;
    edit: boolean;
  };
  map: {
    view: boolean;
  };
  leads: {
    view: boolean;
    create: boolean;
    edit: boolean;
    transfer: boolean;
    history: boolean;
    export: boolean;
  };
  events: {
    view: boolean;
    create: boolean;
    edit: boolean;
    cancel: boolean;
    confirm: boolean;
    transfer: boolean;
    history: boolean;
    export: boolean;
  };
  customers: {
    view: boolean;
    edit: boolean;
    history: boolean;
    lists: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
      export: boolean;
    };
    rewards: {
      view: boolean;
      approve: boolean;
    };
  };
  messages: {
    view: boolean;
    send: boolean;
  };
  payments: {
    view: boolean;
    create: boolean;
    edit: boolean;
    refund: boolean;
  };
  payment_methods: {
    view: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
  };
  products: {
    view: boolean;
    services: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
    addons: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
    packages: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
    coupons: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
  };
  equipment: {
    view: boolean;
    assets: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
    depots: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
    gamescans: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
    blackouts: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
  };
  settings: {
    view: boolean;
    edit: boolean;
  };
  franchisor: {
    view: boolean;
  };
  users: {
    view: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
    grant: boolean;
  };
  reports: {
    view: boolean;
  };
  help: {
    view: boolean;
  };
  ordering: {
    view: boolean;
  };
  resources: {
    view: boolean;
    directory: {
      view: boolean;
      internal: {
        view: boolean;
      };
    };
    store: {
      view: boolean;
    };
  };
}
