import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import { Event } from '@/types';
import { HandThumbUpIcon } from '@heroicons/react/24/solid';

interface StatusBadgeProps {
  event: Event;
  size?: 'xs' | 'sm' | 'lg';
}

export const StatusBadge = ({ event, size = 'sm' }: StatusBadgeProps) => {
  let variant = 'blue' as BadgeProps['variant'];
  switch (event.status) {
    case 'Collections':
      variant = 'orange';
      break;
    case 'Cancelled':
    case 'Canceled':
      variant = 'red';
      break;
    case 'Requested':
    case 'Verified':
      variant = 'blue';
      break;
    case 'Confirmed':
      variant = 'green';
      break;
    default:
      variant = 'blue';
      break;
  }

  let statusText = event.status;
  if (event.waiting_since) {
    statusText = `Waiting / ${event.status}`;
    variant = event.status === 'Cancelled' ? 'red' : 'orange';
  }

  return (
    <Badge size={size} variant={variant}>
      {statusText}
      {!!event.local_verified_at && <HandThumbUpIcon className="w-3 h-3 ml-1" />}
    </Badge>
  );
};
