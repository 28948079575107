import { CustomerBankType } from '@/types';

export const TIME_ONE_HOUR = 1000 * 60 * 60;
export const TIME_ONE_DAY = 1000 * 60 * 60 * 24;

export const LEAD_CLOSE_REASONS: string[] = [
  'Already Booked',
  'Duplicate',
  'Unreachable',
  'Out of Area',
  'Not Interested - Date Unavailable',
  'Not Interested - Date Blackout',
  'Not Interested - Time Unavailable',
  'Not Interested - Service Unavailable',
  'Not Interested - Price',
  'Not Interested - Hired Competitor',
  'Not Interested - Different Activity',
  'Not Interested - Travel Time',
  'Not Interested - Services Not Offered',
  'Not Interested - Other',
  'Trash',
];

interface Option<T> {
  label: string;
  value: T;
}

export const MESSAGE_TEMPLATE_CATEGORIES = [
  {
    label: 'Sales',
    value: 'sales',
  },
  {
    label: 'Refer',
    value: 'refer',
  },
  {
    label: 'Unavailable',
    value: 'unavailable',
  },
  {
    label: 'Info',
    value: 'info',
  },
  {
    label: 'Close Out',
    value: 'close out',
  },
];

export const LEAD_STATUS_OPTIONS: Option<string>[] = [
  { label: 'Active', value: 'Active' },
  { label: 'Checking With Spouse', value: 'CheckWithSpouse' },
  { label: 'Will Call Back', value: 'WillCallBack' },
  { label: 'Email Contact', value: 'EmailContact' },
  { label: 'Needs Call Back', value: 'NeedsCallBack' },
  { label: 'Needs Email Back', value: 'NeedsEmailBack' },
  { label: 'Needs Help - Text Requested', value: 'CustomerNeedsText' },
  { label: 'Needs Help - Call Requested', value: 'CustomerNeedsCall' },
  { label: 'Needs Help with Online Booking', value: 'CustomerNeedsHelp' },
  { label: 'Local Will Follow Up', value: 'LocalWillFollowUp' },
  { label: 'Closed', value: 'Closed' },
  { label: 'Booked', value: 'Booked' },
];

export const TIME_TO_CALL_OPTIONS: Option<string>[] = [
  {
    label: 'Anytime',
    value: 'Anytime',
  },
  {
    label: 'Morning',
    value: 'Morning',
  },
  {
    label: 'Afternoon',
    value: 'Afternoon',
  },
  {
    label: 'Evening',
    value: 'Evening',
  },
];

export const HOW_HEARD_OPTIONS: Option<string>[] = [
  {
    label: 'Repeat customer',
    value: 'Repeat customer',
  },
  {
    label: 'Recommended by friend/family',
    value: 'Recommended by friend/family',
  },
  {
    label: 'Attended a party',
    value: 'Attended a party',
  },
  {
    label: 'Google search',
    value: 'Google search',
  },
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Instagram',
    value: 'Instagram',
  },
  {
    label: 'TikTok',
    value: 'TikTok',
  },
  {
    label: 'YouTube',
    value: 'YouTube',
  },
  {
    label: 'Twitter',
    value: 'Twitter',
  },
  {
    label: 'Pinterest',
    value: 'Pinterest',
  },
  {
    label: 'Hulu',
    value: 'Hulu',
  },
  {
    label: 'Yelp review',
    value: 'Yelp review',
  },
  {
    label: 'Saw the truck',
    value: 'Saw the truck',
  },
  {
    label: 'Saw you at an event',
    value: 'Saw you at an event',
  },
  {
    label: 'Peach Jar',
    value: 'Peach Jar',
  },
  {
    label: 'School Newsletter',
    value: 'School Newsletter',
  },
  {
    label: 'Flyer/Handout',
    value: 'Flyer/Handout',
  },
  {
    label: 'Mailer',
    value: 'Mailer',
  },
  {
    label: 'GameTruck employee',
    value: 'GameTruck employee',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const CUSTOMER_ORGANIZATION_TYPES: Option<string>[] = [
  {
    label: 'City / Gov / Municipality',
    value: 'city_government',
  },
  {
    label: 'Church / Religious',
    value: 'church',
  },
  {
    label: 'Corporate / Other Business',
    value: 'business',
  },
  {
    label: 'Event Planner / Event Organizer',
    value: 'event_planner',
  },
  {
    label: 'Festival',
    value: 'festival',
  },
  {
    label: 'Fundraising Organization',
    value: 'fundraiser',
  },
  {
    label: 'Military',
    value: 'military',
  },
  {
    label: 'Non-Profit Organization',
    value: 'non_profit',
  },
  {
    label: 'Pre-school / Daycare',
    value: 'daycare',
  },
  {
    label: 'PTA/Parent Organization',
    value: 'pta',
  },
  {
    label: 'School (K-12)',
    value: 'school',
  },
  {
    label: 'Summer Camp',
    value: 'summer_camp',
  },
  {
    label: 'University',
    value: 'university',
  },
];

export const CUSTOMER_BANK_TYPE_VALUES = [
  'Seasonal',
  'Investment',
  'Relationship',
  'Key Influencer',
] as const;
export const CUSTOMER_BANK_TYPE_OPTIONS: Option<CustomerBankType>[] = [
  {
    label: 'Seasonal',
    value: 'Seasonal',
  },
  {
    label: 'Investment',
    value: 'Investment',
  },
  {
    label: 'Relationship',
    value: 'Relationship',
  },
  {
    label: 'Key Influencer',
    value: 'Key Influencer',
  },
];

export const EVENT_TYPES: Option<number>[] = [
  {
    label: 'Birthday Party',
    value: 1,
  },
  {
    label: 'Private Party',
    value: 2,
  },
  {
    label: 'Group Event / Large Format',
    value: 3,
  },
  {
    label: 'Charitable Donation',
    value: 4,
  },
  {
    label: 'Corporate',
    value: 5,
  },
  {
    label: 'Promotional',
    value: 6,
  },
  {
    label: 'Educational',
    value: 7,
  },
  {
    label: 'Summer Camp',
    value: 8,
  },
];

export const MONTH_OPTIONS: Option<number>[] = [
  {
    label: 'Jan',
    value: 1,
  },
  {
    label: 'Feb',
    value: 2,
  },
  {
    label: 'Mar',
    value: 3,
  },
  {
    label: 'Apr',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'Jun',
    value: 6,
  },
  {
    label: 'Jul',
    value: 7,
  },
  {
    label: 'Aug',
    value: 8,
  },
  {
    label: 'Sep',
    value: 9,
  },
  {
    label: 'Oct',
    value: 10,
  },
  {
    label: 'Nov',
    value: 11,
  },
  {
    label: 'Dec',
    value: 12,
  },
];

export const DEPOSIT_OPTIONS: Option<string>[] = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Fixed Amount',
    value: 'fixed',
  },
  {
    label: 'Percentage of Total',
    value: 'percent',
  },
];

function durations(): Option<number>[] {
  let hours = 1;
  const durations = [];
  while (hours < 24.5) {
    durations.push({
      label: hours + (hours > 1 ? ' Hrs' : ' Hr'),
      value: hours * 60,
    });
    hours = hours + 0.5;
  }
  return durations;
}
export const SERVICE_LENGTH_OPTIONS: Option<number>[] = durations();

export const SHORT_LENGTH_OPTIONS = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: '5 Mins',
    value: 5,
  },
  {
    label: '10 Mins',
    value: 10,
  },
  {
    label: '15 Mins',
    value: 15,
  },
  {
    label: '30 Mins',
    value: 30,
  },
  {
    label: '45 Mins',
    value: 45,
  },
];
export const SETUP_LENGTH_OPTIONS: Option<number>[] = [...SHORT_LENGTH_OPTIONS, ...durations()];

export const STD_START_TIMES = [
  {
    label: '10:00 am',
    value: '10:00',
  },
  {
    label: '1:00 pm',
    value: '13:00',
  },
  {
    label: '4:00 pm',
    value: '16:00',
  },
  {
    label: '7:00 pm',
    value: '19:00',
  },
];

function startTimes(): Option<number>[] {
  let hours = 1.0;
  const durations = [];
  while (hours < 24.5) {
    durations.push({
      label: String(hours).replace('.', ':') + (hours > 1 ? ' Hrs' : ' Hr'),
      value: hours * 60,
    });
    hours = hours + 0.5;
  }
  return durations;
}

export const ALL_START_TIMES: Option<string>[] = [
  {
    label: '8:00 am',
    value: '08:00',
  },
  {
    label: '8:15 am',
    value: '08:15',
  },
  {
    label: '8:30 am',
    value: '08:30',
  },
  {
    label: '8:45 am',
    value: '08:45',
  },
  {
    label: '9:00 am',
    value: '09:00',
  },
  {
    label: '9:15 am',
    value: '09:15',
  },
  {
    label: '9:30 am',
    value: '09:30',
  },
  {
    label: '9:45 am',
    value: '09:45',
  },
  {
    label: '10:00 am',
    value: '10:00',
  },
  {
    label: '10:15 am',
    value: '10:15',
  },
  {
    label: '10:30 am',
    value: '10:30',
  },
  {
    label: '10:45 am',
    value: '10:45',
  },
  {
    label: '11:00 am',
    value: '11:00',
  },
  {
    label: '11:15 am',
    value: '11:15',
  },
  {
    label: '11:30 am',
    value: '11:30',
  },
  {
    label: '11:45 am',
    value: '11:45',
  },
  {
    label: '12:00 pm',
    value: '12:00',
  },
  {
    label: '12:15 pm',
    value: '12:15',
  },
  {
    label: '12:30 pm',
    value: '12:30',
  },
  {
    label: '12:45 pm',
    value: '12:45',
  },
  {
    label: '1:00 pm',
    value: '13:00',
  },
  {
    label: '1:15 pm',
    value: '13:15',
  },
  {
    label: '1:30 pm',
    value: '13:30',
  },
  {
    label: '1:45 pm',
    value: '13:45',
  },
  {
    label: '2:00 pm',
    value: '14:00',
  },
  {
    label: '2:15 pm',
    value: '14:15',
  },
  {
    label: '2:30 pm',
    value: '14:30',
  },
  {
    label: '2:45 pm',
    value: '14:45',
  },
  {
    label: '3:00 pm',
    value: '15:00',
  },
  {
    label: '3:15 pm',
    value: '15:15',
  },
  {
    label: '3:30 pm',
    value: '15:30',
  },
  {
    label: '3:45 pm',
    value: '15:45',
  },
  {
    label: '4:00 pm',
    value: '16:00',
  },
  {
    label: '4:15 pm',
    value: '16:15',
  },
  {
    label: '4:30 pm',
    value: '16:30',
  },
  {
    label: '4:45 pm',
    value: '16:45',
  },
  {
    label: '5:00 pm',
    value: '17:00',
  },
  {
    label: '5:15 pm',
    value: '17:15',
  },
  {
    label: '5:30 pm',
    value: '17:30',
  },
  {
    label: '5:45 pm',
    value: '17:45',
  },
  {
    label: '6:00 pm',
    value: '18:00',
  },
  {
    label: '6:15 pm',
    value: '18:15',
  },
  {
    label: '6:30 pm',
    value: '18:30',
  },
  {
    label: '6:45 pm',
    value: '18:45',
  },
  {
    label: '7:00 pm',
    value: '19:00',
  },
  {
    label: '7:15 pm',
    value: '19:15',
  },
  {
    label: '7:30 pm',
    value: '19:30',
  },
  {
    label: '7:45 pm',
    value: '19:45',
  },
  {
    label: '8:00 pm',
    value: '20:00',
  },
  {
    label: '8:15 pm',
    value: '20:15',
  },
  {
    label: '8:30 pm',
    value: '20:30',
  },
  {
    label: '8:45 pm',
    value: '20:45',
  },
  {
    label: '9:00 pm',
    value: '21:00',
  },
  {
    label: '9:15 pm',
    value: '21:15',
  },
  {
    label: '9:30 pm',
    value: '21:30',
  },
  {
    label: '9:45 pm',
    value: '21:45',
  },
  {
    label: '10:00 pm',
    value: '22:00',
  },
  {
    label: '10:15 pm',
    value: '22:15',
  },
  {
    label: '10:30 pm',
    value: '22:30',
  },
  {
    label: '10:45 pm',
    value: '22:45',
  },
  {
    label: '11:00 pm',
    value: '23:00',
  },
  {
    label: '11:15 pm',
    value: '23:15',
  },
  {
    label: '11:30 pm',
    value: '23:30',
  },
  {
    label: '11:45 pm',
    value: '23:45',
  },
  {
    label: 'Midnight',
    value: '00:00',
  },
  {
    label: '12:15 am',
    value: '00:15',
  },
  {
    label: '12:30 am',
    value: '00:30',
  },
  {
    label: '12:45 am',
    value: '00:45',
  },
  {
    label: '1:00 am',
    value: '01:00',
  },
  {
    label: '1:15 am',
    value: '01:15',
  },
  {
    label: '1:30 am',
    value: '01:30',
  },
  {
    label: '1:45 am',
    value: '01:45',
  },
  {
    label: '2:00 am',
    value: '02:00',
  },
  {
    label: '02:15 am',
    value: '02:15',
  },
  {
    label: '02:30 am',
    value: '02:30',
  },
  {
    label: '02:45 am',
    value: '02:45',
  },
  {
    label: '03:00 am',
    value: '03:00',
  },
  {
    label: '03:15 am',
    value: '03:15',
  },
  {
    label: '03:30 am',
    value: '03:30',
  },
  {
    label: '03:45 am',
    value: '03:45',
  },
  {
    label: '04:00 am',
    value: '04:00',
  },
  {
    label: '04:15 am',
    value: '04:15',
  },
  {
    label: '04:30 am',
    value: '04:30',
  },
  {
    label: '04:45 am',
    value: '04:45',
  },
  {
    label: '05:00 am',
    value: '05:00',
  },
  {
    label: '05:15 am',
    value: '05:15',
  },
  {
    label: '05:30 am',
    value: '05:30',
  },
  {
    label: '05:45 am',
    value: '05:45',
  },
  {
    label: '06:00 am',
    value: '06:00',
  },
  {
    label: '06:15 am',
    value: '06:15',
  },
  {
    label: '06:30 am',
    value: '06:30',
  },
  {
    label: '06:45 am',
    value: '06:45',
  },
  {
    label: '7:00 am',
    value: '07:00',
  },
  {
    label: '7:15 am',
    value: '07:15',
  },
  {
    label: '7:30 am',
    value: '07:30',
  },
  {
    label: '7:45 am',
    value: '07:45',
  },
];

export const NOTIFICATION_TYPES = [
  {
    type: 'new_lead',
    label: 'New Leads',
  },
  {
    type: 'new_lead_nbe',
    label: 'New Leads (NBE Only)',
  },
  {
    type: 'customer_assistance',
    label: 'Online Booking Assistance Request',
  },
  {
    type: 'new_message',
    label: 'New Messages',
  },
  {
    type: 'customer_quote',
    label: 'Customer Quotes',
  },
  {
    type: 'new_event',
    label: 'New Event Bookings',
  },
  {
    type: 'event_contract',
    label: 'Event Contracts',
  },
  {
    type: 'event_cancel',
    label: 'Event Cancelation',
  },
  {
    type: 'receipt',
    label: 'Customer Payment Receipts',
  },
  {
    type: 'survey_digest',
    label: 'Daily Survey Digest',
  },
  {
    type: 'negative_survey',
    label: 'Negative Survey Alert',
  },
  {
    type: 'job_application',
    label: 'New Job Applications',
  },
];
