import { useCreateCustomerMessage } from '@/api/customers/customer-messages/createCustomerMessage';
import { PolicyGate } from '@/features/auth/authorization';
import { usePresence } from '@/hooks/usePresence';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import { Customer, Event, Lead, LineTypeIntelligenceType, MessageTemplate } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Alert } from '../Elements/Alert/Alert';
import { AvatarGroup } from '../Elements/Avatar/AvatarGroup';
import { FormTextarea } from '../Form';
import { Button } from '../ui/elements/button';
import { Form } from '../ui/form/form';
import { MessageTemplatesDialog } from './MessageTemplatesDialog';

interface SendMessageProps<T> {
  customer: Customer;
  context: T;
  contextType: 'event' | 'lead';
  defaultMessage?: string;
  setDefaultMessage?: (message: string) => void;
}

const schema = z.object({
  message: z.string().min(3, 'Required'),
});

export function SendMessage<T extends Lead | Event>({
  customer,
  context,
  contextType,
  defaultMessage = '',
  setDefaultMessage,
}: SendMessageProps<T>) {
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const { activeLocationId } = useContext(LocationContext);
  const { user } = useContext(AuthContext);

  const { here, updateStatus } = usePresence({
    channelName: `customer-${customer?.id}-messages`,
  });

  const { mutate: createMessage } = useCreateCustomerMessage({
    location: activeLocationId,
    options: {
      onSuccess: () => {
        form.setValue('message', '');
      },
    },
  });

  const handleSendMessage = (values: z.infer<typeof schema>) => {
    createMessage({
      data: {
        customer_id: customer.id,
        message: values.message,
        context: contextType,
        context_id: context?.id,
      },
    });
  };

  const handleApplyMessageTemplate = (template: MessageTemplate) => {
    let message = template.message
      .replace(/\[agent\.first_name\]/g, user?.first_name ?? 'Kai')
      .replace(/\[customer\.first_name\]/g, customer.first_name ?? '');

    if (contextType === 'lead') {
      const lead = context as Lead;
      let wanted = '________';
      if (lead.date_wanted) {
        const parts = lead.date_wanted.split('-');
        if (parts.length === 3) {
          wanted = `${parts[1]}/${parts[2]}/${parts[0]}`;
        }
      }
      message = message.replace(/\[date_wanted\]/g, wanted);
    }

    form.setValue('message', message);
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { message: defaultMessage ?? '' },
  });

  useEffect(() => {
    return () => {
      if (setDefaultMessage) setDefaultMessage(form.getValues('message'));
    };
  }, []);

  const handleActiveMessagingState = (visibility = false) => {
    setShowTemplateDialog(visibility);
    updateStatus({ typing: visibility });
  };

  let placeholder = `Send a text message to ${customer.first_name}...`;
  // Exclude the current user from the user list
  const usersHere = here.filter((hereUser) => hereUser.id !== user?.id.toString());
  const whoIsTyping = usersHere.filter((user) => user.typing);
  if (whoIsTyping.length > 0) {
    placeholder = `${whoIsTyping[0].first_name} is typing...`;
  }

  if (!customer) {
    return <>Customer Not Found.</>;
  }

  return (
    <PolicyGate policy="messages.send">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSendMessage)}>
          {!customer.sms_lead_updates && (
            <Alert title="SMS Sending Disabled" size="sm" color="yellow" className="mt-2">
              This customer has opted out of SMS updates
            </Alert>
          )}
          {customer.phone_valid === 0 && (
            <Alert title="Invalid Phone" size="sm" color="yellow" className="mt-2">
              This customer's phone number appears to be invalid
            </Alert>
          )}
          {customer.phone_type !== null &&
            customer.phone_type !== LineTypeIntelligenceType.mobile && (
              <Alert title="Wrong Phone Type" size="sm" color="yellow" className="mt-2">
                Warning: this{' '}
                {customer.phone_type === LineTypeIntelligenceType.landline
                  ? 'appears to be a landline'
                  : 'may not be a mobile'}{' '}
                phone number and may not receive SMS messages
              </Alert>
            )}
          <div className="flex flex-col mt-4 bg-white border-gray-100 border rounded-md">
            <div className="relative">
              <FormTextarea
                control={form.control}
                name="message"
                className="h-36 border-0 shadow-none resize-y"
                placeholder={placeholder}
                onFieldFocus={() => updateStatus({ typing: true })}
                onFieldBlur={() => updateStatus({ typing: false })}
              />
              <div className="absolute bottom-2 left-2 space-x-2">
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  disabled={!customer.sms_lead_updates}
                  onClick={() => handleActiveMessagingState(true)}
                >
                  Use a Template
                </Button>
              </div>
              <div className="absolute bottom-2 right-2 space-x-2">
                <div className="flex flex-row">
                  <span className="mt-1 mx-2 whitespace-nowrap">
                    <AvatarGroup users={usersHere} size="sm" />
                  </span>

                  <Button
                    type="submit"
                    size="sm"
                    variant="outline"
                    disabled={!customer.sms_lead_updates}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Form>
      <MessageTemplatesDialog
        open={showTemplateDialog}
        onOpenChange={handleActiveMessagingState}
        onSelect={handleApplyMessageTemplate}
      />
    </PolicyGate>
  );
}
