import { featureKeys as calendarFeatureKeys } from '@/api/calendar';
import queryClient from '@/lib/react-query';
import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Event, ModelID } from '@/types';
import { post, update } from '@/utils/api';
import { EventSearchParams, featureKeys, featurePath } from '.';

export type UpdateEventDTO = UpdateDTO<Event, EventSearchParams>;

interface PaymentRefund {
  amount: number;
  payment_id: ModelID;
  reason?: string;
}
export type RefundPaymentDTO = {
  id: ModelID;
  data: PaymentRefund;
  params?: EventSearchParams;
};

export type EventManageQuoteDTO = {
  id: ModelID;
  command: string;
  quote_id?: ModelID | null;
  data: any;
  params?: EventSearchParams;
};

export const chargeModes = {
  Deposit: 'Deposit',
  Partial: 'Partial',
  Final: 'Final',
  Refund: 'Refund',
};
export type ChargeMode = keyof typeof chargeModes;

export const paymentTypes = {
  Cash: 'Cash',
  Check: 'Check',
  CreditCard: 'CreditCard',
  App: 'App',
  Offline: 'Offline',
};
export type PaymentType = keyof typeof paymentTypes;

export type PaymentCommand = {
  amount: number;
  gratuity?: number | null;
  method: string;
  type: ChargeMode;
  check_number?: string | null;
  payment_method_id?: number | null;
};

export type ProcessPaymentDTO = {
  id: ModelID;
  data: PaymentCommand;
  params?: EventSearchParams;
};

export type ConfirmEventDTO = {
  id: ModelID;
  data: {
    send_email: boolean;
  };
  params?: EventSearchParams;
};

export type CancelEventDTO = {
  id: ModelID;
  data: {
    send_email: boolean;
  };
  params?: EventSearchParams;
};

export type SendBookingConfirmationDTO = {
  id: ModelID;
  params?: EventSearchParams;
};

export type SendReceiptDTO = {
  id: ModelID;
  params?: EventSearchParams;
};

export type SendContractDTO = {
  id: ModelID;
  data: {
    types: string[];
  };
  params?: EventSearchParams;
};

interface ClearWaitDTO {
  id: ModelID;
}

const updateEvent = ({ data, id, params = {} }: UpdateEventDTO) =>
  update<Event>(`${featurePath}/${id}`, data, params);

const manageQuote = async ({ id, command, quote_id, data, params }: EventManageQuoteDTO) =>
  post<Event>(`${featurePath}/${id}/manage-quote`, { command, data }, params);

const processPayment = ({ data, id, params = {} }: ProcessPaymentDTO) =>
  post<Event>(`${featurePath}/${id}/process-payment`, data, params);

const confirm = ({ data, id, params = {} }: ConfirmEventDTO) =>
  post<Event>(`${featurePath}/${id}/confirm`, data, params);

const cancel = ({ data, id, params = {} }: CancelEventDTO) =>
  post<Event>(`${featurePath}/${id}/cancel`, data, params);

const sendBookingConfirmation = ({ id, params = {} }: SendBookingConfirmationDTO) =>
  post<Event>(`${featurePath}/${id}/send-booking-confirmation`, params);

const sendReceipt = ({ id, params = {} }: SendReceiptDTO) =>
  post<Event>(`${featurePath}/${id}/send-receipt`, params);

const sendContract = ({ data, id, params = {} }: SendContractDTO) =>
  post<Event>(`${featurePath}/${id}/send-event-contract`, data, params);

const refundPayment = ({ data, id, params = {} }: RefundPaymentDTO) =>
  post<Event>(`${featurePath}/${id}/refund-payment`, data, params);

const clearWait = async ({ id }: ClearWaitDTO) =>
  post<Event>(`${featurePath}/${id}/clear-wait`, {});

export const useUpdateEvent = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: updateEvent,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useEventManageQuote = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: manageQuote,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useProcessPayment = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: processPayment,
    ...options,
  });
};

export const useConfirmEvent = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: confirm,
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: calendarFeatureKeys.all });
      options?.onSuccess?.(data);
    },
  });
};

export const useCancelEvent = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: cancel,
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: calendarFeatureKeys.all });
      options?.onSuccess?.(data);
    },
  });
};

export const useSendBookingConfirmation = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: sendBookingConfirmation,
    ...options,
  });
};

export const useSendReceipt = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: sendReceipt,
    ...options,
  });
};

export const useSendContract = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: sendContract,
    ...options,
  });
};

export const useRefundPayment = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: refundPayment,
    ...options,
  });
};

export const useClearWait = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: clearWait,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};
