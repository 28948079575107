import { useUpdateLead } from '@/api/leads/leads/updateLead';
import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { Lead } from '@/types';
import { todayAsCalendarDate } from '@/utils/format';
import { isMobile } from 'react-device-detect';

interface FollowUpContextMenuProps {
  lead: Lead;
  onSuccess?: () => void;
}

const calculateFollowUp = (days = 1) => {
  return {
    followup_at: todayAsCalendarDate().add({ days }).toString(),
    status: 'NeedsCallBack',
  };
};

export const FollowUpContextMenu = ({ lead, onSuccess }: FollowUpContextMenuProps) => {
  const { mutate: updateLead } = useUpdateLead();

  const FollowUpActions = [
    {
      label: 'Follow Up - Tomorrow',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(1) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Two days',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(2) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Next Week',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(7) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Two Weeks',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(14) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Next Month',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(28) }, { onSuccess }),
    },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size={isMobile ? 'xs' : 'sm'}>
          <>
            <span className="whitespace-nowrap">Follow Up</span>
            {lead.followup_at && (
              <span className="hidden md:inline text-xs pl-1">{lead.followup_at}</span>
            )}
          </>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {FollowUpActions.map((action, i) => {
          return (
            <DropdownMenuItem
              key={`ddm-archive-${i}`}
              onClick={action.onClick}
              className="cursor-pointer"
            >
              {action.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
