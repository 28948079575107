import {
  useCancelEvent,
  useConfirmEvent,
  useSendBookingConfirmation,
  useSendContract,
  useSendReceipt,
} from '@/api/events/events/updateEvent';
import { useVerifyEvent } from '@/api/events/events/verifyEvent';
import { CustomerListDropdownMenuGroup } from '@/components/Dropdown/CustomerListDropdownMenuGroup';
import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { PolicyGate } from '@/features/auth/authorization';
import { useFileDownload } from '@/hooks/useFileDownload';
import { LocationContext } from '@/providers/location';
import { Event } from '@/types';
import { getRaw, postRaw } from '@/utils/api';
import {
  ArrowDownTrayIcon,
  ArrowRightOnRectangleIcon,
  BanknotesIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  EllipsisVerticalIcon,
  FolderIcon,
  HandThumbUpIcon,
  InboxIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Link, useNavigate } from '@tanstack/react-router';
import { useContext, useState } from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { CancelEventDialog } from './Detail/CancelEventDialog';
import { ConfirmEventDialog } from './Detail/ConfirmEventDialog';
import { CopyEventDialog } from './Detail/CopyEventDialog';
import { SendContractDialog } from './Detail/SendContractDialog';
import { TransferEventDialog } from './Detail/TransferEventDialog';

interface EventActionDropdownMenuProps {
  event: Event;
  children?: React.ReactNode;
}

export const EventActionDropdownMenu = ({ event, children }: EventActionDropdownMenuProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const navigate = useNavigate();
  const [downloadPending, setDownloadIsPending] = useState(false);
  const { download } = useFileDownload();

  const { mutate: confirmEvent } = useConfirmEvent();
  const { mutate: verifyEvent } = useVerifyEvent();
  const { mutate: sendBookingConfirmation } = useSendBookingConfirmation();
  const { mutate: sendReceipt } = useSendReceipt();
  const { mutate: cancelEvent } = useCancelEvent();
  const { mutate: sendContract } = useSendContract();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showContractDialog, setShowContractDialog] = useState(false);

  const handleConfirmEvent = (sendEmail: boolean) => {
    confirmEvent({
      id: event.id,
      data: {
        send_email: sendEmail,
      },
    });
  };

  const handleVerifyEvent = (sendEmail: boolean) => {
    verifyEvent({
      id: event.id,
      data: {
        send_email: false,
      },
    });
  };

  const handleCancelEvent = (sendEmail: boolean) => {
    cancelEvent({
      id: event.id,
      data: {
        send_email: sendEmail,
      },
    });
  };

  const handleResendBookingConfirmation = () => {
    sendBookingConfirmation({
      id: event.id,
    });
  };

  const handleSendReceipt = () => {
    sendReceipt({
      id: event.id,
    });
  };

  const handleSendContract = (data: { types: string[] }) => {
    sendContract({
      id: event.id,
      data,
    });
  };

  const handleDownloadInvoice = async (mode: 'download' | 'open') => {
    setDownloadIsPending(true);
    const result = await getRaw<Blob>(`/v4/events/event/${event.id}/invoice`, {});
    download(result, `gametruck-invoice-EID#${event.id}.pdf`, mode);
    setDownloadIsPending(false);
  };

  const handleDownloadStatement = async (mode: 'download' | 'open') => {
    setDownloadIsPending(true);
    const result = await postRaw<Blob>(`/v4/events/event/${event.id}/statement`, {});
    download(result, `event-statement-${event.id}-${Date.now()}.pdf`, mode);
    setDownloadIsPending(false);
  };

  return (
    <PolicyGate policy="events.view">
      <DropdownMenu>
        {children ? (
          children
        ) : (
          <DropdownMenuTrigger asChild>
            <Button variant="action">
              <span className="sr-only">More Actions</span>
              <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
            </Button>
          </DropdownMenuTrigger>
        )}
        <DropdownMenuContent side="bottom" align="end" className="w-56">
          <DropdownMenuLabel>Shortcuts</DropdownMenuLabel>
          <PolicyGate policy="payments.create">
            <DropdownMenuItem
              onClick={() => navigate({ to: `/events/event/${event.id}/payments` })}
              className="cursor-pointer"
            >
              <BanknotesIcon className="mr-2 h-4 w-4" />
              Process Payment
            </DropdownMenuItem>
          </PolicyGate>
          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <FolderIcon className="mr-2 h-4 w-4" />
                <span>Open</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem>
                    <Link to={`/events/event/${event.id}/overview`} className="cursor-pointer">
                      Open Event
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Link
                      to={`/customers/customer/${event.customer_id}/overview`}
                      className="cursor-pointer"
                    >
                      Open Customer
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Link to={`/leads/lead/${event.lead_id}/quote`} className="cursor-pointer">
                      Open Source Lead
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <DocumentIcon className="mr-2 h-4 w-4" />
                <span>Documents</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem
                    onClick={() => handleDownloadInvoice('open')}
                    disabled={downloadPending}
                    className="cursor-pointer"
                  >
                    Open Invoice
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => handleDownloadStatement('open')}
                    disabled={downloadPending}
                    className="cursor-pointer"
                  >
                    Open Statement
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
                <span>Downloads</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem
                    onClick={() => handleDownloadInvoice('download')}
                    disabled={downloadPending}
                    className="cursor-pointer"
                  >
                    Download Invoice
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => handleDownloadStatement('download')}
                    disabled={downloadPending}
                    className="cursor-pointer"
                  >
                    Download Statement
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />

          <PolicyGate policy="events.confirm">
            <DropdownMenuGroup>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <InboxIcon className="mr-2 h-4 w-4" />
                  <span>Communications</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem
                      onClick={handleResendBookingConfirmation}
                      className="cursor-pointer"
                    >
                      Resend Booking Confirmation
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleSendReceipt} className="cursor-pointer">
                      Resend Receipt
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => setShowContractDialog(true)}
                      className="cursor-pointer"
                    >
                      Send Event Contract
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
          </PolicyGate>

          <CustomerListDropdownMenuGroup customer={event.customer} />
          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            <PolicyGate policy="events.confirm">
              <DropdownMenuItem
                onClick={() => setShowConfirmDialog(true)}
                className="cursor-pointer"
              >
                <CheckCircleIcon className="mr-2 h-4 w-4" />
                Confirm Event
              </DropdownMenuItem>
            </PolicyGate>
            {event.confirmed_at && (
              <PolicyGate policy="events.confirm">
                <DropdownMenuItem
                  onClick={() => handleVerifyEvent(false)}
                  className="cursor-pointer"
                >
                  <HandThumbUpIcon className="mr-2 h-4 w-4" />
                  Verify Event
                </DropdownMenuItem>
              </PolicyGate>
            )}
            <PolicyGate policy="events.create">
              <DropdownMenuItem onClick={() => setShowCopyDialog(true)} className="cursor-pointer">
                <DocumentDuplicateIcon className="mr-2 h-4 w-4" />
                Copy Event
              </DropdownMenuItem>
            </PolicyGate>
            <PolicyGate policy="events.transfer">
              <DropdownMenuItem
                onClick={() => setShowTransferDialog(true)}
                className="cursor-pointer"
              >
                <ArrowRightOnRectangleIcon className="mr-2 h-4 w-4" />
                Transfer Event
              </DropdownMenuItem>
            </PolicyGate>
          </DropdownMenuGroup>

          <PolicyGate policy="events.cancel">
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => setShowCancelDialog(true)}
              className="cursor-pointer bg-red-200 text-red-900 hover:bg-red-300"
            >
              <XCircleIcon className="mr-2 h-4 w-4" />
              Cancel Event
            </DropdownMenuItem>
          </PolicyGate>
        </DropdownMenuContent>
      </DropdownMenu>

      <ConfirmEventDialog
        open={showConfirmDialog}
        onOpenChange={setShowConfirmDialog}
        onSubmit={handleConfirmEvent}
      />
      <CancelEventDialog
        open={showCancelDialog}
        onOpenChange={setShowCancelDialog}
        onSubmit={handleCancelEvent}
      />
      <CopyEventDialog source={event} open={showCopyDialog} onOpenChange={setShowCopyDialog} />
      <TransferEventDialog
        event={event}
        open={showTransferDialog}
        onOpenChange={setShowTransferDialog}
      />
      <SendContractDialog
        open={showContractDialog}
        onOpenChange={setShowContractDialog}
        onSubmit={handleSendContract}
      />
    </PolicyGate>
  );
};
