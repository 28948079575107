import { EquipmentPolicies } from '@/features/equipment/policies';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import { User } from '@/types';
import { useCallback, useContext } from 'react';
import { CalendarPolicies } from '../calendar/policies';
import { CustomerPolicies } from '../customers/policies';
import { EventPolicies } from '../events/policies';
import { LeadPolicies } from '../leads/policies';
import { ProductPolicies } from '../products/policies';
import { ResourcesPolicies } from '../resources/policies';
import { ACL, AclRole, ACL_ROLES } from './acl';
import { ACL_ROLE_NEWBIE } from './acl/newbie';

type PolicyCheckProps = {
  acl: ACL;
  user: User;
  meta?: any;
};

export type PolicyCheck = (props: PolicyCheckProps) => boolean;

const AplicationPolicies: Record<string, PolicyCheck> = {
  admin: ({ acl }) => acl.is_admin,
  'location.active': ({ acl, meta }) => meta.location?.franchise_id > 0,
  'dashboard.view': ({ acl }) => acl.dashboard.view,
  'dashboard.news': ({ acl }) => acl.dashboard.news,
  'dashboard.motd': ({ acl }) => acl.dashboard.motd,
  'scheduler.view': ({ acl }) => acl.scheduler.view,
  'scheduler.edit': ({ acl }) => acl.scheduler.edit,
  'map.view': ({ acl }) => acl.map.view,
  'settings.view': ({ acl }) => acl.settings.view,
  'settings.edit': ({ acl }) => acl.settings.edit,
  'reports.view': ({ acl }) => acl.reports.view,
  'help.view': () => true,
  'franchisor.view': ({ acl }) => acl.franchisor.view,
  'messages.view': ({ acl }) => acl.messages.view,
  'messages.send': ({ acl }) => acl.messages.send,
  'payments.view': ({ acl }) => acl.payments.view,
  'payments.create': ({ acl }) => acl.payments.create,
  'payments.update': ({ acl }) => acl.payments.edit,
  'payments.refund': ({ acl }) => acl.payments.refund,
  'payment_methods.view': ({ acl }) => acl.payment_methods.view,
  'payment_methods.create': ({ acl }) => acl.payment_methods.create,
  'payment_methods.update': ({ acl }) => acl.payment_methods.edit,
  'payment_methods.delete': ({ acl }) => acl.payment_methods.delete,
  ...CalendarPolicies,
  ...CustomerPolicies,
  ...LeadPolicies,
  ...EventPolicies,
  ...ProductPolicies,
  ...EquipmentPolicies,
  ...ResourcesPolicies,
};

export const useAuthorization = () => {
  const { authenticated, user, location_access } = useContext(AuthContext);
  const { activeLocationId } = useContext(LocationContext);

  const activeRole = location_access?.find(
    (access) => access.franchise_id === activeLocationId,
  )?.type;

  const acl = ACL_ROLES[activeRole ?? ACL_ROLE_NEWBIE];

  if (!acl) {
    throw Error(`ACL ${activeRole} does not exist!`);
  }

  if (!authenticated || !user || !acl) {
    throw Error('User does not exist!');
  }

  const checkRole = useCallback(
    ({ allowedRoles }: { allowedRoles: AclRole[] }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.map((role) => activeRole === role).includes(true);
      }

      return false;
    },
    [activeRole],
  );

  const checkPolicy = useCallback(
    ({ policy, meta }: { policy: string; meta?: any }) => {
      if (policy) {
        if (!AplicationPolicies[policy]) {
          throw Error(`Policy ${policy} does not exist!`);
        }
        return AplicationPolicies[policy]({ acl, user, meta });
      }

      return false;
    },
    [acl],
  );

  return { checkRole, checkPolicy, role: activeRole, permissions: acl };
};

type RoleGateProps = {
  allowedRoles: AclRole[];
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
};

export const RoleGate = ({ allowedRoles, forbiddenFallback = null, children }: RoleGateProps) => {
  const { checkRole } = useAuthorization();

  const canAccess = checkRole({ allowedRoles });

  return <>{canAccess ? children : forbiddenFallback}</>;
};

type PolicyGateProps = {
  policy: string;
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
  meta?: any;
};

export const PolicyGate = ({
  policy,
  forbiddenFallback = null,
  children,
  meta,
}: PolicyGateProps) => {
  const { checkPolicy } = useAuthorization();

  const canAccess = checkPolicy({ policy, meta });

  return <>{canAccess ? children : forbiddenFallback}</>;
};
