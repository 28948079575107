import { PolicyCheck } from '@/features/auth/authorization';
import { AssetPolicies } from './assets';
import { BlackoutPolicies } from './blackouts';
import { DepotPolicies } from './depots';

export const EquipmentPolicies: Record<string, PolicyCheck> = {
  'equipment.view': ({ acl, user, meta }) => {
    return acl.equipment.view;
  },
  ...AssetPolicies,
  ...DepotPolicies,
  ...BlackoutPolicies,
};
