import { useClearWait } from '@/api/events/events/updateEvent';
import { ConversationThread } from '@/components/Conversations/ConversationThread';
import { SendMessage } from '@/components/Conversations/SendMessage';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { Button } from '@/components/ui/elements/button';
import { EventContext } from '@/features/events/contexts/EventContext';
import { Event } from '@/types';
import { useContext } from 'react';

export const MessagesTab = () => {
  const { event, temporaryTextMessage, setTemporaryTextMessage } = useContext(EventContext);
  const { mutate: clearWait } = useClearWait();

  if (!event) return <>Loading...</>;

  return (
    <div>
      {event.customer ? (
        <>
          <ConversationThread customerId={event.customer.id} />
          <div>
            {event.customer && (
              <SendMessage<Event>
                customer={event.customer}
                context={event}
                contextType="event"
                defaultMessage={temporaryTextMessage}
                setDefaultMessage={setTemporaryTextMessage}
              />
            )}
          </div>
          <div className="flex flex-row justify-end my-3 mx-2">
            <Button variant="outline" size="sm" onClick={() => clearWait({ id: event.id })}>
              Clear Waiting Status
            </Button>
          </div>
        </>
      ) : (
        <NoResults label="customer" />
      )}
    </div>
  );
};
