import { useClearWait } from '@/api/leads/leads/updateLead';
import { ConversationThread } from '@/components/Conversations/ConversationThread';
import { SendMessage } from '@/components/Conversations/SendMessage';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { Button } from '@/components/ui/elements/button';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { Lead } from '@/types';
import { useContext } from 'react';

export const MessagesTab = () => {
  const { mutate: clearWait } = useClearWait();
  const { lead, temporaryTextMessage, setTemporaryTextMessage } = useContext(LeadContext);

  if (!lead) return <>Loading...</>;

  return (
    <div className="-mr-5 -mt-3 mb-16">
      {lead.customer ? (
        <>
          <ConversationThread customerId={lead.customer.id} />
          <div className="px-2">
            {lead.customer && (
              <SendMessage<Lead>
                customer={lead.customer}
                context={lead}
                contextType="lead"
                defaultMessage={temporaryTextMessage}
                setDefaultMessage={setTemporaryTextMessage}
              />
            )}
          </div>
          <div className="flex flex-row justify-end my-3 mx-2">
            <Button variant="outline" size="sm" onClick={() => clearWait({ id: lead.id })}>
              Clear Waiting Status
            </Button>
          </div>
        </>
      ) : (
        <NoResults label="customer" />
      )}
    </div>
  );
};
