import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import { Lead } from '@/types';

interface ConditionBadgeProps {
  lead: Lead;
  size?: 'xs' | 'sm' | 'lg';
}

export const ConditionBadge = ({ lead, size = 'sm' }: ConditionBadgeProps) => {
  let variant = 'red' as BadgeProps['variant'];
  const condition = lead.waiting_since ? 'waiting' : lead.condition;
  switch (condition) {
    case 'action-required':
      variant = 'orange';
      break;
    case 'pending':
      variant = 'yellow';
      break;
    case 'resolved':
      variant = 'green';
      break;
    default:
      variant = 'red';
      break;
  }

  let statusText = lead.status;
  if (lead.waiting_since) {
    statusText = `Waiting / ${lead.status}`;
  }
  if (lead.status === 'Closed') {
    statusText = `${lead.status} / ${lead.close_reason}`;
    variant = 'gray';
  }

  return (
    <Badge size={size} variant={variant}>
      {statusText}
    </Badge>
  );
};
